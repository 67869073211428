import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGetLeaderboardQuery } from "../../../auth/data-access/store/services/auth.service";
import { Navigation } from "swiper";
import cx from "classnames";
import { Link } from "react-router-dom";

import { Leaderboard } from "../../interfaces/auth.types";

import "./leaderboard-slider.scss";

const LeaderboardSlider = () => {
  const limit = 20;

  const { data: leaderboardData } = useGetLeaderboardQuery({
    limit,
    offset: 0,
  });

  const [activeGroupIndex, setActiveGroupIndex] = useState(0);

  const swiperRef = useRef<typeof Swiper>(null);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);

  const handleSlideChange = () => {
    if (swiperRef.current) {
      // @ts-ignore
      setActiveGroupIndex(swiperRef.current.activeIndex);
    }
  };

  const renderItems = (items: Leaderboard["items"]) => {
    return items?.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <Link to="/leaderboard" className="leaderboard-item">
            <div className="leaderboard-item__rank">{item.rank}</div>
            <div className="leaderboard-item__avatar">
              {item.rank === 1 && (
                <img
                  src="/img/star.png"
                  alt=""
                  className="leaderboard-item__star"
                />
              )}
              <img
                src={
                  item.rank === 1
                    ? "/img/avatar-bg-gold.png"
                    : "/img/avatar-bg.png"
                }
                alt=""
                className="leaderboard-item__avatar-border"
              />
              <img
                src={item.avatar}
                alt="avatar"
                className="leaderboard-item__avatar-image"
              />
              <img
                src="/img/icns/diamond-with-shadow.png"
                alt="avatar"
                className="leaderboard-item__diamond"
              />
              {item.rank === 1 && (
                <img
                  src="/img/crown.png"
                  alt=""
                  className="leaderboard-item__crown"
                />
              )}
            </div>
            <div className="leaderboard-item__score">{item.points}</div>
            <div className="leaderboard-item__name">{item.nickname}</div>
          </Link>
        </SwiperSlide>
      );
    });
  };

  return (
    <div
      className="leaderboard-slider"
      style={{ paddingLeft: activeGroupIndex === 0 ? "0" : "25px" }}
    >
      <Swiper
        onSwiper={(swiper) => {
          // @ts-ignore
          swiperRef.current = swiper;
        }}
        onInit={(swiper) => {
          // Link custom navigation buttons to Swiper after initialization
          if (
            swiper &&
            swiper.params.navigation &&
            typeof swiper.params.navigation !== "boolean"
          ) {
            swiper.params.navigation.prevEl = prevButtonRef.current;
            swiper.params.navigation.nextEl = nextButtonRef.current;
          }
          swiper.navigation.update();
        }}
        onSlideChange={handleSlideChange}
        modules={[Navigation]}
        navigation={{
          prevEl: prevButtonRef.current,
          nextEl: nextButtonRef.current,
        }}
        pagination={false}
        effect={"slide"}
        slidesPerView={4}
        slidesPerGroup={4}
        loop={false}
        spaceBetween={7}
        style={{ paddingLeft: activeGroupIndex === 0 ? "25px" : "0" }}
      >
        {leaderboardData?.items ? renderItems(leaderboardData.items) : null}
      </Swiper>

      <img
        ref={prevButtonRef}
        src="/img/icns/chevron-right.svg"
        className={cx(
          "leaderboard-slider__prev",
          activeGroupIndex === 0 && "leaderboard-slider__prev_disabled"
        )}
        alt="Previous"
      />
      <img
        ref={nextButtonRef}
        src="/img/icns/chevron-right.svg"
        className={cx(
          "leaderboard-slider__next",
          activeGroupIndex === limit - 4 && "leaderboard-slider__next_disabled"
        )}
        alt="Next"
      />
    </div>
  );
};

export default LeaderboardSlider;
