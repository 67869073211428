import { ButtonStore } from "../../shared/ui/game-store-button";
import { Avatar } from "../../shared/ui/avatar";
import { Background } from "../../shared/ui/background";
import { Button } from "../../shared/ui/button";
import { NavBar } from "../../shared/ui/navbar";
import { PointsArea } from "../../shared/ui/points-area";
import { PrizeToWin } from "../../shared/ui/prize-to-win";
import { UpstreamServiceBanner } from "../../shared/ui/upstream-service-banner";

import "./home.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  selectIsFirstSignIn,
  selectToken,
  selectUser,
} from "../../auth/data-access/store/authSlice";
import { Link, useNavigate, useNavigationType } from "react-router-dom";
import {
  selectInit,
  selectLanguage,
  selectShowWelcomeBackModal,
  selectTranslations,
  updateShowWelcomeModal,
} from "../../shared/data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../utils/translation";
import { motion, Variants } from "framer-motion";
import { reset } from "../../shared/data-access/store/game/gameSlices";
import useLocals from "../../utils/hooks/use-locals";
import useAmbientSound from "../../utils/hooks/use-ambient-sound";
import SoundManager from "../../utils/managers/sound-manager";
import { useEffect, useState } from "react";
import { Modal } from "../../shared/ui/modal";
import { fadeOutBgAudio } from "../../utils/fade-audio";
import { setTutorial } from "../../shared/data-access/store/ui/uiSlice";
import LeaderboardSlider from "../../shared/ui/leaderboard-slider/leaderboard-slider";

const contentVariants: Variants = {
  hidden: {
    y: -20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.2,
    },
  },
};

const buttonVariants: Variants = {
  hidden: {
    scale: 0,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.5,
    },
  },
};

const prizesVariants: Variants = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.2 * custom,
    },
  }),
};

export const checkEligibleToPlay = ({
  eligible,
  canPlay,
  disableBuyCredits,
  subscriptionResourceStatus,
  texts,
}: {
  eligible: boolean;
  canPlay: boolean;
  disableBuyCredits: boolean;
  subscriptionResourceStatus?:
    | "subscribed"
    | "unsubscribed"
    | "not_eligible_subscribed"
    | "never_subscribed";
  texts: {
    btnPlay: string;
    btnPlayBuy: string | JSX.Element;
    descriptionFinishedAll: string;
    descriptionBuy: string;
    descriptionSubscribe: string | JSX.Element;
  };
}): {
  disabled: boolean;
  textButton: string | JSX.Element;
  description: string | JSX.Element;
  buyCredits: boolean;
} => {
  let disabled = false;
  let textButton: string | JSX.Element = texts.btnPlay;
  let description: string | JSX.Element = "";
  let buyCredits = false;
  if (eligible && canPlay) {
    disabled = false;
    textButton = texts.btnPlay;
    description = "";
  } else if (!eligible && !canPlay) {
    if (subscriptionResourceStatus === "unsubscribed") {
      disabled = true;
      textButton = texts.btnPlay;
      description = texts.descriptionSubscribe;
    } else if (subscriptionResourceStatus === "not_eligible_subscribed") {
      disabled = true;
      textButton = texts.btnPlay;
      description = texts.descriptionBuy;
    } else {
      disabled = true;
      textButton = texts.btnPlay;
      description = texts.descriptionFinishedAll;
    }
  } else if (eligible && !canPlay) {
    if (!disableBuyCredits) {
      disabled = false;
      textButton = texts.btnPlayBuy;
      description = "";
      buyCredits = true;
    } else {
      disabled = true;
      textButton = texts.btnPlay;
      description = texts.descriptionFinishedAll;
    }
  }

  return {
    disabled,
    textButton,
    description,
    buyCredits,
  };
};

export function Home() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const init = useAppSelector(selectInit);
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  const { setValue } = useLocals();
  const isFirstSignIn = useAppSelector(selectIsFirstSignIn);
  const [playAmbient, setPlayAmbient] = useState(false);
  useAmbientSound("home", playAmbient);
  const navigationType = useNavigationType();
  const token = useAppSelector(selectToken);
  const isGuest = token === process.env.REACT_APP_GUEST_KEY;
  const showWelcomeBackModal = useAppSelector(selectShowWelcomeBackModal);

  useEffect(() => {
    if (isFirstSignIn && !isGuest) {
      navigate("/onboarding");
    }
  }, [isFirstSignIn]);

  useEffect(() => {
    if (isGuest) {
      navigate("/opponent");
    } else {
      if (!localStorage.getItem("fromLogin") && navigationType === "POP") {
        toggleModal();
      } else {
        localStorage.removeItem("fromLogin");
        setPlayAmbient(true);
      }
    }
  }, []);

  const handleStartTutorial = () => {
    dispatch(setTutorial({ show: "home", step: 0 }));
  };

  const toggleModal = () => {
    if (showWelcomeBackModal) {
      dispatch(updateShowWelcomeModal(false));
      setPlayAmbient(true);
      localStorage.removeItem("fromLogin");
    } else {
      dispatch(updateShowWelcomeModal(true));
    }
  };

  const handlePlay = () => {
    fadeOutBgAudio(SoundManager.ambientSounds.home);
    setTimeout(() => {
      dispatch(reset());
      setValue("triggerNewGame", "yes");
      SoundManager.vfxSounds.play.play();
      navigate("/opponent");
    }, 500);
  };

  const handleBuyCredits = () => {
    navigate("/store", { state: "coins" });
  };

  const { disabled, textButton, description, buyCredits } = checkEligibleToPlay(
    {
      eligible: user?.eligibility?.eligible || false,
      canPlay: user?.eligibility?.canPlay || false,
      disableBuyCredits: init?.features?.disableBuyCredits || false,
      subscriptionResourceStatus: user?.eligibility?.subscriptionResourceStatus,
      texts: {
        btnPlay: checkTranslationKey(translations[lang]?.Play_now, "Play now!"),
        btnPlayBuy: (
          <>
            {checkTranslationKey(
              translations[lang]?.Play_again_for,
              "Play again for"
            )}
            <img src="/img/icns/coin.svg" alt="" />
            {" 1"}
          </>
        ),
        descriptionFinishedAll: checkTranslationKey(
          translations[lang]?.You_played_all_the_games_of_the_day,
          "You played all the games of the day! See you again tomorrow!"
        ),
        descriptionBuy: checkTranslationKey(
          translations[lang]?.You_cannot_play_right_now_Check_your_balance,
          "You cannot play right now. Check your balance and try again."
        ),
        descriptionSubscribe: (
          <>
            {checkTranslationKey(
              translations[lang]?.You_cannot_play_right_now_Click,
              "You cannot play right now. Click "
            )}
            <Link to="/auth/subscribe">
              {checkTranslationKey(translations[lang]?.here, "here")}
            </Link>
            {checkTranslationKey(
              translations[lang]?.to_subscribe,
              " to subscribe."
            )}
          </>
        ),
      },
    }
  );

  return (
    <div id="home">
      <Background type="home">
        <NavBar
          // slotEnd={
          //   <NavbarScore
          //     scoreType="primary"
          //     score={22}
          //     units="Level"
          //     icon={"level"}
          //   ></NavbarScore>
          // }
          slotEnd={
            <img
              src="/img/icns/icon-info.png"
              alt=""
              className={"start-tutorial-icon"}
              onClick={handleStartTutorial}
            />
          }
        ></NavBar>
        <div className="home-content">
          <div className="content-area">
            <motion.div
              className="points-area"
              variants={contentVariants}
              initial="hidden"
              animate="visible"
            >
              <div className="user-points">
                <div className="user">
                  <Avatar
                    image={user?.character?.avatar || ""}
                    className="user-image"
                    level={user?.character?.level?.current}
                  ></Avatar>
                  <div className="user-name">{user?.character?.nickname}</div>
                  <div className="user-edit">
                    <Link to="/profile/edit" className="edit-button">
                      <img src="/img/icns/pencil-fill.svg" alt="edit" />
                    </Link>
                  </div>
                </div>
                <div className="points">
                  <PointsArea
                    count={user?.character?.points}
                    icon="diamonds"
                    label={checkTranslationKey(
                      translations[lang]?.Diamonds_for_grand_prize,
                      "Diamonds for the grand prize"
                    )}
                    type="primary"
                    itemCount={0}
                    total={5}
                  ></PointsArea>
                  <PointsArea
                    count={
                      (user?.eligibility?.credits?.purchased || 0) +
                      (user?.eligibility?.credits?.subscription || 0)
                    }
                    icon="coins"
                    label={checkTranslationKey(
                      translations[lang]?.Coins,
                      "Coins"
                    )}
                    itemCount={1}
                    total={5}
                  ></PointsArea>
                  <PointsArea
                    count={isGuest ? "∞" : user?.character?.skills?.lives}
                    icon="lives"
                    label={checkTranslationKey(
                      translations[lang]?.Lives,
                      "Lives"
                    )}
                    itemCount={2}
                    total={5}
                  ></PointsArea>
                </div>
              </div>
              <motion.div
                className="button-placement"
                variants={buttonVariants}
                initial="hidden"
                animate="visible"
              >
                <Button
                  buttonType="success-play"
                  disabled={disabled}
                  onClick={buyCredits ? handleBuyCredits : handlePlay}
                >
                  {textButton}
                </Button>
              </motion.div>

              {description && (
                <div className="no-games-text">{description}</div>
              )}

              <div className="no-games-text">Data charges apply</div>
            </motion.div>

            <div className="leaderboard-slider-wrapper">
              <LeaderboardSlider />
            </div>

            <div className="shop-area">
              <motion.div
                className="prizes"
                variants={prizesVariants}
                initial="hidden"
                animate="visible"
                custom={1}
              >
                <PrizeToWin />
              </motion.div>

              {!init?.features?.disableBuyCredits && (
                <motion.div
                  className="game-store"
                  variants={prizesVariants}
                  initial="hidden"
                  animate="visible"
                  custom={2}
                >
                  <ButtonStore />
                </motion.div>
              )}
            </div>
          </div>
        </div>

        <Modal show={showWelcomeBackModal} setShow={toggleModal}>
          <div className="modal-content">
            <div className="top-area">
              <div className="message">
                <p>Welcome back!</p>
              </div>
            </div>
            <div className="bottom-area">
              <Button onClick={toggleModal} buttonType="success">
                OK
              </Button>
            </div>
          </div>
        </Modal>
      </Background>
      <div className="stream-service">
        <UpstreamServiceBanner />
      </div>
    </div>
  );
}

export default Home;
